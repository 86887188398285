import { atom, useAtom } from "jotai";
import { useCallback } from "react";
import { useUserContext } from "../../context/UserContext";
import { reclaim } from "../../reclaim-api";
import { PartialTeam, PartialTeamInvitation, PartialTeamMember, Team, TeamInvitation, TeamMember } from "../../reclaim-api/team/Team";

const teamAtom = atom<Team | null>(null);
const userMembershipAtom = atom<TeamMember | null>(null);

export type TeamContext = {
  team: Team | null;
  userMembership: TeamMember | null;
  actions: {
    invite: (invites: PartialTeamInvitation[]) => Promise<TeamInvitation[]>;
    load: () => Promise<void>;
    uninvite: (id: string) => Promise<void>;
    removeMember: (userId: string) => Promise<void>;
    patchMember: (userId: string, member: PartialTeamMember) => Promise<void>;
    patchTeam: (team: PartialTeam) => Promise<void>;
  };
};

export const useTeam = (): TeamContext => {
  const [team, setTeam] = useAtom(teamAtom);
  const [userMembership, setUserMembership] = useAtom(userMembershipAtom);

  const [{ user }, actions] = useUserContext();

  /**
   * Actions
   */

  const load = useCallback(async () => {
    let teamData: Team;

    try {
      teamData = await reclaim.team.get();
    } catch (err) {
      throw new Error("Could not load team and billing information.", { cause: err });
    }

    await setTeam(teamData);

    const membership = teamData.members?.find((m) => !!user?.id && m.teamMemberId?.userId === user?.id);
    await setUserMembership(membership || null);
  }, [user, setTeam, setUserMembership]);

  const invite = useCallback(
    async (invites: PartialTeamInvitation[]) => {
      let invitations: TeamInvitation[] = [];
      try {
        invitations = await reclaim.team.invite(invites);
      } catch (err) {
        throw new Error("Team invitation request failed.", { cause: err });
      }

      await load();

      return invitations;
    },
    [load]
  );

  const uninvite = useCallback(
    async (id: string) => {
      try {
        await reclaim.team.deleteInvitation(id);
      } catch (err) {
        throw new Error("Could not revoke team invitation.", { cause: err });
      }

      await load();
    },
    [load]
  );

  const removeMember = useCallback(
    async (userId: string) => {
      try {
        await reclaim.team.deleteMember(userId);
      } catch (err) {
        throw new Error("Could not remove user from team.", { cause: err });
      }

      await load();
    },
    [load]
  );

  const patchTeam = useCallback(
    async (team: PartialTeam) => {
      try {
        await reclaim.team.patchTeam(team);
      } catch (err) {
        throw new Error("Could not remove user from team.", { cause: err });
      }

      await load();
    },
    [load]
  );

  const patchMember = useCallback(
    async (userId: string, member: PartialTeamMember) => {
      try {
        await reclaim.team.patchMember(userId, member);
      } catch (err) {
        throw new Error("Could not patch team member.", { cause: err });
      }

      // Get updated user if patch is for active user.
      if (userId === user?.id) {
        await actions?.load();
      }

      await load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [load, user?.id] // Do not include user actions to avoid over rendering
  );

  /**
   * End Actions
   */

  return {
    team,
    userMembership,
    actions: { invite, load, uninvite, removeMember, patchMember, patchTeam },
  };
};
