import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useUserContext } from "../../context/UserContext";
import { useTeam } from "./useTeam";

const upsells = [
  "unlimited active habits",
  "unlimited calendar syncs",
  "unlimited Smart 1:1s",
  "custom decompression event titles",
  "all third-party integrations",
];

const daysLeftInTrialAtom = atom<number | null>(null);
const upsellAtom = atom<string>(upsells[Math.floor(Math.random() * upsells.length)]);

export type TrialExpirationContext = {
  daysLeftInTrial: number | null;
  upsell: string;
};

export const useTrialExpiration = (): TrialExpirationContext => {
  const {
    team,
    actions: { load },
  } = useTeam();
  const [{ user }] = useUserContext();

  const [daysLeftInTrial, setDaysLeftInTrial] = useAtom(daysLeftInTrialAtom);
  const [upsell] = useAtom(upsellAtom);

  useEffect(() => {
    const userMember = team?.members.find((member) => !!user?.id && member.teamMemberId?.userId === user?.id);
    if (!userMember?.trialEnd) return;

    const expMs = userMember.trialEnd.getTime();

    // Only show if trialEnd is upcoming
    if (expMs <= new Date().getTime()) {
      void setDaysLeftInTrial(null);
      return;
    }

    function updateDays(): void {
      const days = Math.ceil((expMs - new Date().getTime()) / (1000 * 60 * 60 * 24));
      void setDaysLeftInTrial(days);
    }

    const interval = setInterval(updateDays, 1000 * 60 * 60);
    updateDays();

    () => clearInterval(interval);
  }, [team?.members, user?.id, setDaysLeftInTrial]);

  useEffect(() => {
    if (!team) {
      void load();
    }
  }, [team, load]);

  return { daysLeftInTrial, upsell };
};
