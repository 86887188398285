import { Box, Button, Chip, ListItem, ListItemProps, useMediaQuery, useTheme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import clsx from "clsx";
import { MouseEvent, useCallback, useMemo, VFC } from "react";
import BadgeCheckSvg from "../../img/badge-check.svg";
import { ConnectedAccount } from "../../reclaim-api/Accounts";
import { ConfirmButton } from "../ConfirmButton";
import { Tooltip } from "../Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
  account: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  main: {},
  avatar: {
    height: 32,
    marginRight: theme.spacing(2),
    width: 32,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  },
  large: {
    [theme.breakpoints.up("sm")]: {
      height: 48,
      width: 48,
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
  },
  accountName: {
    flexGrow: 1,
    maxWidth: "80%",
  },
  noMaxWidth: {
    maxWidth: "100%",
    wordBreak: "break-word",
  },
  name: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  chip: {
    backgroundColor: "transparent",
    marginLeft: theme.spacing(-1),
    padding: 0,
  },
  mainChip: {
    color: theme.palette.grey[600],
  },
  errorChip: {
    color: theme.palette.error.dark,
  },
  infoChip: {
    color: theme.palette.text.secondary,
  },
  chipIcon: {
    height: 16,
    marginRight: theme.spacing(0.5),
    width: 16,
  },
  chipLabel: {
    alignItems: "center",
    display: "inline-flex",
  },
  deleteBtn: {
    color: theme.palette.error.dark,
  },
  confirmTitle: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    marginLeft: theme.spacing(5),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(7),
      width: "auto",
    },
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  useAsMainBtn: {
    whiteSpace: "nowrap",
  },
}));

export type AccountListItemProps = ListItemProps & {
  className?: string;
  account: ConnectedAccount;
  onUseAsMain?: (e: MouseEvent<HTMLButtonElement>, account: ConnectedAccount) => Promise<boolean | void>;
  onReconnect?: (e: MouseEvent<HTMLButtonElement>, account: ConnectedAccount) => Promise<boolean | void>;
  onDelete?: (e: MouseEvent<HTMLButtonElement>, account: ConnectedAccount) => Promise<boolean | void>;
};

export const AccountListItem: VFC<AccountListItemProps> = ({
  className,
  account,
  onUseAsMain,
  onReconnect,
  onDelete,
  button, // TODO (IW): ignored because MUI flips out about it being conditional
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUseAsMainClick = useCallback(
    (e) => {
      onUseAsMain?.(e, account);
    },
    [account, onUseAsMain]
  );

  const handleReconnectClick = useCallback(
    (e) => {
      onReconnect?.(e, account);
    },
    [account, onReconnect]
  );

  const handleDeleteClick = useCallback(
    (e) => {
      onDelete?.(e, account);
    },
    [account, onDelete]
  );

  const repairBtn = useMemo(() => {
    return (
      !account.valid && (
        <Box>
          <Button color="primary" component="a" key={account.id} onClick={handleReconnectClick}>
            Reconnect
          </Button>
        </Box>
      )
    );
  }, [account.id, account.valid, handleReconnectClick]);

  const switchMainBtn = useMemo(() => {
    return (
      !!account.switchToMainURI &&
      !!account.valid && (
        <Box>
          <Button
            className={classes.useAsMainBtn}
            color="primary"
            component="a"
            key={account.id}
            onClick={handleUseAsMainClick}
          >
            Use as Main
          </Button>
        </Box>
      )
    );
  }, [account.id, account.valid, account.switchToMainURI, classes.useAsMainBtn, handleUseAsMainClick]);

  const deleteBtn = useMemo(() => {
    return (
      !!account.canDelete && (
        <Box>
          <ConfirmButton
            className={classes.deleteBtn}
            key={account.id}
            variant="text"
            color="inherit"
            onConfirm={handleDeleteClick}
            title=""
            message={
              <>
                <Typography className={classes.confirmTitle} variant="h4">
                  Delete {account.name}?
                </Typography>
                <Typography>
                  Are you sure you want to delete this account? Any Calendar Syncs you have set up with{" "}
                  <em>{account.name}</em> will also be deleted.
                </Typography>
              </>
            }
            DialogProps={{
              maxWidth: small ? false : "sm",
              fullScreen: small,
            }}
          >
            Delete
          </ConfirmButton>
        </Box>
      )
    );
  }, [account.canDelete, account.id, account.name, classes.confirmTitle, classes.deleteBtn, handleDeleteClick, small]);

  return (
    <ListItem
      className={clsx(classes.account, className, {
        [classes.main]: !!account.main,
      })}
      {...rest}
    >
      <Avatar className={clsx(account.main && classes.large, classes.avatar)} src={account.avatar} />
      <Box className={clsx(classes.accountName, !repairBtn && !switchMainBtn && !deleteBtn && classes.noMaxWidth)}>
        <Typography variant="subtitle2" component="h3" className={classes.name}>
          {account.name}
        </Typography>
        {!!account.main && (
          <Tooltip
            title="We use the calendar from your main account to see your availability so that we can block time for your tasks & habits"
            arrow
            placement="bottom"
          >
            <Chip
              classes={{
                label: classes.chipLabel,
              }}
              className={clsx(classes.chip, classes.mainChip)}
              size="small"
              label={
                <>
                  <BadgeCheckSvg className={classes.chipIcon} />
                  Main Account
                </>
              }
            />
          </Tooltip>
        )}
        {!account.valid && (
          <Chip
            classes={{
              label: classes.chipLabel,
            }}
            className={clsx(classes.chip, classes.errorChip)}
            size="small"
            label={
              <>
                <ReportProblemOutlinedIcon className={classes.chipIcon} />
                Disconnected
              </>
            }
          />
        )}
        {!account.unique && (
          <Tooltip
            title="Can't be used as main because it's connected to at least one other Reclaim account"
            arrow
            placement="bottom"
          >
            <Chip
              classes={{
                label: classes.chipLabel,
              }}
              className={clsx(classes.chip, classes.infoChip)}
              size="small"
              label={
                <>
                  <InfoOutlinedIcon className={classes.chipIcon} />
                  Multiple connections
                </>
              }
            />
          </Tooltip>
        )}
      </Box>
      {(!!repairBtn || !!switchMainBtn || !!deleteBtn) && (
        <Box className={classes.buttonContainer}>
          {repairBtn}
          {switchMainBtn}
          {deleteBtn}
        </Box>
      )}
    </ListItem>
  );
};
