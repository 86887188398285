import { Box, lighten, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { VFC } from "react";
import { ReclaimEdition } from "../../reclaim-api/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: "inline-block",
    backgroundColor: lighten(theme.colors.grey, 0.6),
    borderRadius: 4,
    lineHeight: "1em",
  },
  paid: {
    backgroundColor: theme.colors.logo.corn,
  },
  label: {
    padding: theme.spacing(0, 0.5),
    textTransform: "uppercase",
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightMedium,
    height: 16,
    lineHeight: "16px",
  },
  medium: {},
  small: {},
  slim: {},
}));

export type EditionBadgeProps = {
  edition: ReclaimEdition;
  className?: string;
  size?: "slim" | "small" | "medium";
};

export const EditionBadge: VFC<EditionBadgeProps> = ({ edition, className, size = "medium" }) => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      className={clsx(classes.root, className, {
        [classes.paid]: edition !== ReclaimEdition.Free,
      })}
    >
      <Typography
        className={clsx(classes.label, {
          [classes.medium]: size === "medium",
          [classes.small]: size === "small",
          [classes.slim]: size === "slim",
        })}
        component="span"
      >
        {edition.label}
      </Typography>
    </Box>
  );
};
